<template>
    <div class="row justify-content-center">
        <div class="card shadow-none border-0 col-lg-10">
            <div class="card-body rounded-0 text-left">
                <h2 class="fw-700 display1-size display2-md-size mb-4 text-center">Change your password</h2>                        
                <form @submit.prevent="changePass" class="my-5">
                    <div class="form-group icon-input mb-3">
                        <input v-model="form.oldPassword" type="Password" required class="style2-input pl-5 form-control text-grey-900 font-xss ls-3" placeholder="Old Password">
                        <i class="font-sm ti-lock text-grey-500 pr-0"></i>
                    </div>
                    <div class="form-group icon-input mb-3">
                        <input v-model="form.newPassword" :type="isVisible2 ? 'text' : 'password'" required class="style2-input pl-5 form-control text-grey-900 font-xss ls-3" placeholder="New Password">
                        <i class="font-sm ti-lock text-grey-500 pr-0"></i>
                    </div>
                    <div class="form-group icon-input mb-1">
                        <input v-model="form.passConfirm" :type="isVisible ? 'text' : 'password'" required class="style2-input pl-5 form-control text-grey-900 font-xss ls-3" placeholder="Confirm Password">
                        <i class="font-sm ti-lock text-grey-500 pr-0"></i>
                    </div>
                    <div class="form-check text-left mb-3">
                        <input v-model="isVisible2" type="checkbox" class="form-check-input mt-2" id="exampleCheck1">
                        <label class="form-check-label font-xsss text-grey-500" for="exampleCheck2">Show New password</label>
                    </div>
                    <div class="form-check text-left mb-3">
                        <input v-model="isVisible" type="checkbox" class="form-check-input mt-2" id="exampleCheck2">
                        <label class="form-check-label font-xsss text-grey-500" for="exampleCheck2">Show Confirmation password</label>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12 p-0 text-center">
                        <input type="submit" class="btn btn-current text-white w-100" value="Change Password">
                    </div>
                </form>
                
            
            </div>
        </div> 
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: "ChangePassword",
    data(){
        return{
            isVisible: false,
            isVisible2: false,
            form:{
                oldPassword:'',
                newPassword: '',
                passConfirm:'',
            },
        }
    },
    created() {
    },
    methods:{
        async changePass(){
            var data = {
                old_password : this.form.oldPassword,
                new_password: this.form.newPassword,
                new_password_confirmation: this.form.passConfirm,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/account/password`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then((res) => {
                this.$swal({
                    toast: true,
                    title: 'Success Change Password!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'Home' })
            }).catch((err) => {
                if (err.response.status == 422){
                    this.validationErrors = Object.values(err.response.data.errors);
                    // this.$toastr.error(this.validationErrors[0][0], 'failed Create New Course !');
                    this.$swal({
                        toast: true,
                        title: 'Failed Change Password',
                        text: this.validationErrors[0][0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        }
    }
}
</script>